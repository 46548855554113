import React from 'react';
import { Routes, Route } from 'react-router-dom';
import '@fontsource/inter';
import '@fontsource/roboto-mono';
import '@fontsource/poppins';
import { Helmet } from 'react-helmet';

import { routes } from './routes/routes';
import { BackgroundProvider, useBackground } from './utils/BackgroundContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App: React.FC = () => {
    return (
        <div className="App PickfordLabs">
            <Helmet>
                <script type="text/javascript">
                    {`
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_ID}");
                    `}
                </script>
            </Helmet>
            <BackgroundProvider routes={routes}>
                <Content />
            </BackgroundProvider>
        </div>
    );
};

// Separate Content component to use the background context
const Content: React.FC = () => {
    const { backgroundColor } = useBackground();

    return (
        <div className="content" style={{ backgroundColor }}>
            <Routes>
                {routes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                ))}
                <Route path="*" element={routes[0].element} />
            </Routes>
        </div>
    );
};

export default App;
